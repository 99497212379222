import {Inject, Injectable, InjectionToken} from '@angular/core';
import {StorageUtil} from '../util/storage.util';

// export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
// 	providedIn: 'root',
// 	factory: () => localStorage
// });

@Injectable({
	providedIn: 'root'
})
export class BrowserStorageService {
	// constructor(@Inject(BROWSER_STORAGE) public storage: Storage) {}

	get(key: string) {
		return StorageUtil.getSesionStorage(key);
	}

	set(key: string, value: string) {
		return StorageUtil.saveSessionStorage(key, value);
	}

	remove(key: string) {
		return StorageUtil.removeSessionStorage(key);
	}

	clear() {
		return StorageUtil.clearSessionStorage();
	}
}