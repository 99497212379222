export class StorageUtil {

	public static saveSessionStorage(key: string, data: string) {
		try {
			if (typeof window !== 'undefined' && window.sessionStorage) {
				sessionStorage.setItem(key, data);
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static getSesionStorage(key: string) {
		try {
			if (typeof window !== 'undefined' && window.sessionStorage) {
				return sessionStorage.getItem(key);
			}
			return null;
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
			return null;
		}
	}

	public static removeSessionStorage(key: string) {
		try {
			if (typeof window !== 'undefined' && window.sessionStorage) {
				sessionStorage.removeItem(key);
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static clearSessionStorage() {
		try {
			if (typeof window !== 'undefined' && window.sessionStorage) {
				sessionStorage.clear();
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static saveLocalStorage(key: string, data: string) {
		try {
			if (typeof window !== 'undefined' && window.localStorage) {
				localStorage.setItem(key, data);
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static getLocalStorage(key: string) {
		try {
			if (typeof window !== 'undefined' && window.localStorage) {
				localStorage.getItem(key);
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static removeLocalStorage(key: string) {
		try {
			if (typeof window !== 'undefined' && window.localStorage) {
				localStorage.removeItem(key);
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}

	public static clearLocalStorage() {
		try {
			if (typeof window !== 'undefined' && window.localStorage) {
				localStorage.clear();
			}
		} catch (error) {
			console.error('Failed to save to localStorage:', error);
		}
	}


}